import React from "react";
import { Typography, Stack } from "@mui/material";
import _ from "lodash";
import NewLayout from "../../components/NewLayout";
import Container from "../../components/Container";
import BoxDetails from "../../components/BoxDetails";
import InterestedItems from "../../components/InterestedItems";
import { headers } from "../../utils/navbar";

const faqsContent = () => [
  {
    title: "Which is the listed entity?",
    description: "Allfunds Group plc is the holding company of the Group.",
  },
  {
    title: "What is Allfunds' ticker symbol?",
    description: "ALLFG.",
  },
  {
    title: "On which exchange is Allfunds traded?",
    description: "Euronext Amsterdam Stock Exchange.",
  },
  {
    title: "When did Allfunds become a public listed entity?",
    description: "We became a publicly listed entity on 23 April 2021.",
  },
  {
    title: "How can I purchase shares of Allfunds?",
    description: `Shares of ALLFG can be purchased on the open market through any
        registered broker.`,
  },
  {
    title: "Do you have banking license?",
    description: `Yes, Allfunds Group plc owns 100% of Allfunds Bank, S.A.U. which has a
        banking license and is registered in the Register of Credit Institutions
        in Spain as a fully operational credit institution for the development
        of the activities of this type of entity and supervised by the European
        Central Bank and the Bank of Spain. Therefore, Allfunds Bank’s business
        is supervised, regulated and under the laws and regulations applied by
        the Bank of Spain, the Spanish Securities Markets Exchange Commission
        (Comisión Nacional del Mercado de Valores) and the Commission for the
        Prevention of Money Laundering and Monetary Infringements (Comisión de
        Prevención del Blanqueo de Capitales e Infracciones Monetarias).`,
  },
  {
    title: "Who is Allfunds' auditor?",
    description: "Ernst&Young are our auditors since 2024.",
  },
  {
    title: "Which regulations apply to the Group?",
    description: ` As a UK entity, we are subject to FCA oversight. As a listed entity in
        Amsterdam, we are subject to AFM oversight, as well as Dutch takeover
        rules and we voluntarily comply with Dutch Corporate Governance Code.
        Our business activities must comply with any local regulation in which
        we are present and namely MiFID Regulations, Solvency, European
        Directives, GDPR, Anti-Money Laundering and Spanish banking regulations
        among others.`,
  },
];

function FaqsPage() {
  const interestedItems = [
    { ..._.get(headers(), "contact") },
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.governance.elements.allfunds_bank") },
  ];
  return (
    <NewLayout byDefault apps="investors.faqs">
      <Container pb={8}>
        <Typography my={3} variant="h2">
          FAQs
        </Typography>
        <Stack spacing={2}>
          {faqsContent().map(({ title, description }) => (
            <BoxDetails title={title} open>
              <Typography>{description}</Typography>
            </BoxDetails>
          ))}
        </Stack>
        <InterestedItems items={interestedItems.splice(0, 3)} />
      </Container>
    </NewLayout>
  );
}

export default FaqsPage;
