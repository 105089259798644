import styled from "@emotion/styled";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import DownloadPdfButton from "./DownloadPdfButton";

function BoxDetailsItems({ title, items, isMobile }) {
  const [show, setShow] = useState(!title);

  return (
    <Grid container spacing={1} alignItems="center">
      {title && (
        <Grid item xs={12} pr={1}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography variant="h5">{title.replaceAll("_", " ")}</Typography>
            <FontAwesomeIcon
              onClick={() => setShow(!show)}
              icon={show ? faAngleDown : faAngleUp}
              style={{
                cursor: "pointer",
                width: isMobile ? "1rem" : "20px",
                height: isMobile ? "1rem" : "20px",
              }}
            />
          </Stack>
        </Grid>
      )}
      {!_.isEmpty(items) ? (
        show ? (
          items.map(
            ({ attributes: { title, file, publish_date, external_path } }) => (
              <Grid item xs={12} sm={6} md={4} margin="0px !important">
                <DownloadPdfButton
                  title={title}
                  path={external_path || file.data?.attributes.url}
                  ext={external_path ? ".link" : file.data?.attributes.ext}
                  publishDate={publish_date}
                />
              </Grid>
            ),
          )
        ) : (
          <></>
        )
      ) : (
        <Grid item xs={12}>
          <Typography>
            <Trans>No documents for this year</Trans>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

function BoxDetails({
  title,
  description,
  subtitle,
  image,
  children,
  items = [],
  isLigth = false,
  open = false,
  ...rest
}) {
  const [showDetails, setShowDetails] = React.useState(open);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const _items = _.groupBy(
    _.orderBy(items, "attributes.subcategory.data.attributes.order"),
    "attributes.subcategory.data.attributes.title",
  );

  return (
    <StyledBox padding={2} isLigth={isLigth} {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={title && subtitle ? "start" : "center"}
      >
        <Box>
          {title.props?.children ? (
            title
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
          {subtitle && <Typography>{subtitle}</Typography>}
        </Box>
        {!open && (
          <FontAwesomeIcon
            onClick={() => setShowDetails(!showDetails)}
            icon={showDetails ? faChevronCircleUp : faChevronCircleDown}
            style={{
              cursor: "pointer",
              width: isMobile ? "2rem" : "49px",
              height: isMobile ? "2rem" : "49px",
            }}
          />
        )}
      </Box>
      {showDetails && (
        <>
          <Box
            mt={1.5}
            mb={2.5}
            sx={{
              borderTop: (theme) => `2px solid ${theme.palette.secondary.main}`,
            }}
          />
          {image || description ? (
            <Box px={!isMobile && 2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                  <img
                    alt="details_image"
                    src={image}
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      filter: "drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.16))",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  {description}
                </Grid>
              </Grid>
            </Box>
          ) : !_.isEmpty(_items) ? (
            <Stack spacing={1} divider={<Divider />}>
              {Object.entries(_items).map(([key, values]) => (
                <BoxDetailsItems
                  items={values}
                  isMobile={isMobile}
                  title={key === "null" || key === "undefined" ? null : key}
                />
              ))}
            </Stack>
          ) : (
            <StyledChildren>{children}</StyledChildren>
          )}
        </>
      )}
    </StyledBox>
  );
}

const StyledChildren = styled(Box)`
  font-size: 16px;
  * p {
    font-size: 16px;
  }
`;

const StyledBox = styled(Box)`
  border-radius: 5px;
  border: ${({ theme, isLigth }) =>
    `1px solid ${isLigth ? "white" : theme.palette.primary.main}`};
  * {
    color: ${({ theme, isLigth }) =>
      `${isLigth ? "white" : theme.palette.primary.main}`};
  }
`;

export default BoxDetails;
