import React from "react";
import { Trans } from "@lingui/macro";
import { Typography, Box, Stack, Grid, useMediaQuery } from "@mui/material";
import ArrowButton from "./ArrowButton";
import { defaultInterestedItems } from "../utils/navbar";
import ImageCard from "./ImageCard";

function InterestedItems({
  withoutTitle = false,
  isSmall = false,
  items = defaultInterestedItems,
  isDark = false,
  children,
  ...rest
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ mt: { xs: 4, sm: 8 } }} {...rest}>
      {!withoutTitle && (
        <Stack
          mb={5}
          spacing={1}
          direction={isMobile ? "column" : "row"}
          alignItems="baseline"
        >
          <Typography
            color={isDark && "white"}
            variant="h3"
            whiteSpace={!isMobile && "nowrap"}
          >
            <Trans>You may also be interested in</Trans>
          </Typography>
          <Box
            sx={{
              width: "100%",
              borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
            }}
          />
        </Stack>
      )}
      {children || (
        <Grid container spacing={2}>
          {items.map(
            ({
              label,
              alternativeTitle,
              path,
              image,
              description = undefined,
            }) => (
              <Grid item xs={12} sm={6} md={isSmall ? 3 : 4}>
                {isSmall ? (
                  <Box
                    sx={{
                      borderRadius: "5px",
                      height: "210px",
                      backgroundSize: "cover !important",
                      // backgroundPosition: "center !important",
                      background: `linear-gradient(180deg, rgba(23, 48, 83, 8e-05) 0%, rgba(18, 35, 61, 0.24) 31.77%, rgba(18, 38, 66, 0.68) 63.02%), url(${image})`,
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.305882)",
                    }}
                    p={2}
                    display="grid"
                    alignItems="end"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        mr={1}
                        lineHeight="22px"
                        color="white"
                        fontWeight={700}
                      >
                        {alternativeTitle || label}
                      </Typography>
                      <ArrowButton path={path} />
                    </Box>
                  </Box>
                ) : (
                  <ImageCard
                    linkPath={path}
                    linkTitle={<Trans>Read more</Trans>}
                    title={label}
                    image={image}
                    description={description}
                    isSmall
                  />
                )}
              </Grid>
            ),
          )}
        </Grid>
      )}
    </Box>
  );
}

export default InterestedItems;
