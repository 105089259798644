import * as React from "react";
import { Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import ArrowButton from "./ArrowButton";

function ImageCard({
  image,
  header,
  title,
  description,
  onClick,
  height,
  isSmall = false,
  linkPath = "#",
  linkTitle = <Trans>Find out more</Trans>,
}) {
  return (
    <StyledImage image={image} isSmall={isSmall} height={height}>
      <Box>
        <Box className={`content ${!isSmall && "big"}`} mb={2}>
          {header && <StyledTypography mb={2} variant="small" lineHeight="22px">{header}</StyledTypography>}
          <StyledTypography variant={isSmall ? "h4" : "h3"} lineHeight={`${isSmall ? 30 : 40}px`}>
            {title}
          </StyledTypography>
          {description && (
            <Box mt={0.5}>
              <StyledTypography variant="small">{description}</StyledTypography>
            </Box>
          )}
        </Box>
        <ArrowButton path={linkPath} title={linkTitle} onClick={onClick} />
      </Box>
    </StyledImage>
  );
}

export default ImageCard;

const StyledTypography = styled(Typography)`
  color: white;
`;

const StyledImage = styled(Box)(({
  theme, image, height, isSmall,
}) => `
  ${theme.breakpoints.down("sm")} {
    height: 400px;
    padding: ${theme.spacing(2)};
  }
  ${theme.breakpoints.up("sm")} {
    height: ${isSmall ? "380px" : height || "500px"};
    padding: ${theme.spacing(isSmall ? 2 : 5)}
  }
  background: ${
  image
    ? `linear-gradient(180deg, rgba(23, 48, 83, 8e-05) 0%, rgba(18, 35, 61, 0.24) 31.77%, rgba(18, 38, 66, 0.68) 63.02%), url(${image})`
    : theme.palette.primary.main
};
  background-size: cover;
  border-radius: 5px;
  display: flex;
  align-items: end;
  .content {
    max-height: 12rem;
  }
  .big {
    ${theme.breakpoints.up("sm")} {
      min-height: 8rem;
    }
  }
`);
