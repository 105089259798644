import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/pro-light-svg-icons";

function ArrowIcon({ direction = "right", ...rest }) {
  return (
    <StyledIcon
      icon={direction === "right" ? faArrowRightLong : faArrowLeftLong}
      color="white"
      size="2x"
      {...rest}
    />
  );
}

const StyledIcon = styled(FontAwesomeIcon)`
  color:  ${({ color }) => `${color || "white"}`};
  border:  ${({ color }) => `2px solid ${color || "white"}`};
  border-radius: 50%;
  padding: 10px;
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
`;

export default ArrowIcon;
