import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { LocalizedLink } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import { theme } from "../theme";
import ArrowIcon from "./ArrowIcon";

function ArrowButton({
  title, path = "/", color, contentColor, extraStyles = {}, onClick = undefined, isDark = false, variant = "contained", ...rest
}) {
  const colorButton = color || (isDark ? "primary" : "inherit");
  const colorContent = contentColor || (isDark || variant === "outlined" ? "white" : theme.palette.primary.main);
  const isExternalPath = path.includes("http");

  const renderButton = () => (
    <>
      {title ? (
        <StyledButton
          variant={variant}
          colorContent={colorContent}
          color={colorButton}
          size="large"
          endIcon={(
            <FontAwesomeIcon
              style={{ paddingLeft: "0.5rem" }}
              icon={faArrowRightLong}
            />
            )}
          {...(onClick || isExternalPath
            ? {
              onClick: () => (onClick ? onClick() : window.open(path, "_blank")),
            }
            : {})}
          sx={{ cursor: "pointer", ...extraStyles }}
        >
          <Typography>{title}</Typography>
        </StyledButton>
      ) : (
        <ArrowIcon
          {...(onClick || isExternalPath
            ? {
              onClick: () => (onClick ? onClick() : window.open(path, "_blank")),
            }
            : {})}
          color={!isDark ? "white" : theme.palette.primary.main}
        />
      )}
    </>
  );

  return (
    <Box {...rest}>
      {onClick || isExternalPath ? (
        renderButton()
      ) : (
        <LocalizedLink to={path}>{renderButton()}</LocalizedLink>
      )}
    </Box>
  );
}

const StyledButton = styled(Button)`
  min-height: 38px;
  > p, * p, span, path  {
    color: ${({ colorContent }) => `${colorContent} !important`};
  }
`;

export default ArrowButton;
